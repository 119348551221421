import { Col, Grid, Row } from '@devstart/react-bootstrap';
import { graphql, withPrefix } from 'gatsby';
import type { TFunction } from 'i18next';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';

import VideoPlayer from '../../components/video-player';
import ChallengeDescription from '../../components/challenge-description';
import ChallengeTitle from '../../components/challenge-title';
import CompletionModal from '../../components/completion-modal';
import Hotkeys from '../../components/hotkeys';

import Spacer from '../../../../components/helpers/spacer';
import LearnLayout from '../../../../components/layouts/learn';

import {
  challengeMounted,
  openModal,
  submitChallenge,
  updateChallengeMeta,
  updateSolutionFormValues,
  isAllowedChallenge
} from '../../redux/actions';
import {
  completedChallengesIdsSelector,
  isChallengeCompletedSelector
} from '../../redux/selectors';

import {
  ChallengeMeta,
  ChallengeNode,
  User
} from '../../../../redux/prop-types';
import { hardGoTo as navigate } from '../../../../redux/actions';
import {
  isSignedInSelector,
  signInLoadingSelector,
  userRegistrationStatusSelector,
  userSelector
} from '../../../../redux/selectors';
import SolutionForm from '../solution-form';
import { isUserAccessAllowed } from '../../../../utils/general-functions';
import { RegistrationResponseProps } from '../../../../utils/ajax';
import { checkIsCertificateCheckpoint } from '../../../../../../utils/nacional-utils';
import { AccessLevel } from '../../../../utils/enums/access-levels';

import './show.css';

const mapStateToProps = createSelector(
  isChallengeCompletedSelector,
  userSelector,
  isSignedInSelector,
  signInLoadingSelector,
  completedChallengesIdsSelector,
  userRegistrationStatusSelector,
  (
    isChallengeCompleted: boolean,
    user: User,
    isSignedIn: boolean,
    signInLoading: boolean,
    completedChallengeIds: string[],
    userRegistrationStatus: RegistrationResponseProps
  ) => ({
    isChallengeCompleted,
    user,
    isSignedIn,
    signInLoading,
    completedChallengeIds,
    userRegistrationStatus
  })
);

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      updateChallengeMeta,
      challengeMounted,
      updateSolutionFormValues,
      openCompletionModal: () => openModal('completion'),
      openNacionalModal: () => openModal('nacionalCertificate'),
      submitChallenge,
      isAllowedChallenge,
      navigate
    },
    dispatch
  );

// Types
interface ProjectProps {
  challengeMounted: (arg0: string) => void;
  data: { challengeNode: ChallengeNode };
  completedChallengeIds: string[];
  isChallengeCompleted: boolean;
  openCompletionModal: () => void;
  openNacionalModal: () => void;
  submitChallenge: () => void;
  pageContext: {
    challengeMeta: ChallengeMeta;
  };
  t: TFunction;
  updateChallengeMeta: (arg0: ChallengeMeta) => void;
  updateSolutionFormValues: () => void;
  user: User;
  isSignedIn: boolean;
  signInLoading: boolean;
  navigate: (location: string) => void;
  isAllowedChallenge: () => void;
  userRegistrationStatus: RegistrationResponseProps;
}

// Component
class Project extends Component<ProjectProps> {
  static displayName: string;
  private _container: HTMLElement | null = null;

  constructor(props: ProjectProps) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const {
      challengeMounted,
      data: {
        challengeNode: {
          challenge: { title, challengeType, helpCategory }
        }
      },
      pageContext: { challengeMeta },
      updateChallengeMeta,
      signInLoading,
      isSignedIn,
      isAllowedChallenge
    } = this.props;

    if (!signInLoading) {
      if (!this.handleEnabledAccess()) {
        isAllowedChallenge();
      }
      if (!isSignedIn) {
        navigate(withPrefix('/'));
      }
    }

    updateChallengeMeta({
      ...challengeMeta,
      title,
      challengeType,
      helpCategory
    });
    challengeMounted(challengeMeta.id);
    this._container?.focus();
  }

  componentDidUpdate(prevProps: ProjectProps): void {
    const {
      data: {
        challengeNode: {
          challenge: { title: prevTitle }
        }
      }
    } = prevProps;
    const {
      challengeMounted,
      data: {
        challengeNode: {
          challenge: { title: currentTitle, challengeType, helpCategory }
        }
      },
      pageContext: { challengeMeta },
      updateChallengeMeta
    } = this.props;
    if (prevTitle !== currentTitle) {
      updateChallengeMeta({
        ...challengeMeta,
        title: currentTitle,
        challengeType,
        helpCategory
      });
      challengeMounted(challengeMeta.id);
    }
  }

  handleEnabledAccess = () => {
    const {
      pageContext: { challengeMeta },
      completedChallengeIds,
      data: {
        challengeNode: { challenge }
      },
      user,
      userRegistrationStatus
    } = this.props;

    return isUserAccessAllowed(
      challengeMeta,
      completedChallengeIds,
      challenge,
      user,
      userRegistrationStatus?.Row?.length > 0
    );
  };

  handleSubmit({
    showCompletionModal
  }: {
    showCompletionModal: boolean;
  }): void {
    if (showCompletionModal) {
      this.props.submitChallenge();

      const isCheckpoint: boolean = checkIsCertificateCheckpoint(
        this.props.data.challengeNode.challenge
      );

      if (
        isCheckpoint &&
        (this.props.user.access === AccessLevel.CompleteNacional ||
          this.props.user.access === AccessLevel.LimitedNacional)
      ) {
        this.props.openNacionalModal();
      } else {
        this.props.openCompletionModal();
      }
    }
  }

  render() {
    const {
      data: {
        challengeNode: {
          challenge: {
            fields: { blockName },
            challengeType,
            title,
            description,
            instructions,
            superBlock,
            certification,
            block,
            phase,
            translationPending,
            videoId
          }
        }
      },
      isChallengeCompleted,
      pageContext: {
        challengeMeta: { nextChallengePath, prevChallengePath }
      },
      t,
      updateSolutionFormValues
    } = this.props;

    const blockNameTitle = `${t(
      `intro:${superBlock}.phases.${phase}.blocks.${block}.title`
    )} - ${title}`;

    return (
      <Hotkeys
        innerRef={(c: HTMLElement | null) => (this._container = c)}
        nextChallengePath={nextChallengePath}
        prevChallengePath={prevChallengePath}
      >
        <LearnLayout>
          <Helmet
            title={`${blockNameTitle} | ${t('learn.learn')} | DEVstart`}
          />
          <Grid>
            <Row>
              <Col md={8} mdOffset={2} sm={10} smOffset={1} xs={12}>
                <Spacer size='medium' />
                <div className='container-project-solution'>
                  <ChallengeTitle
                    isCompleted={isChallengeCompleted}
                    translationPending={translationPending}
                  >
                    {title}
                  </ChallengeTitle>
                  {videoId && (
                    <div className='video-wrapper'>
                      <VideoPlayer videoId={videoId} />
                    </div>
                  )}

                  <ChallengeDescription
                    description={description}
                    instructions={instructions}
                  />
                  <SolutionForm
                    challengeType={challengeType}
                    description={description}
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    onSubmit={this.handleSubmit}
                    updateSolutionForm={updateSolutionFormValues}
                  />
                  <Spacer size='small' />
                </div>
              </Col>
              <CompletionModal
                block={block}
                blockName={blockName}
                certification={certification}
                superBlock={superBlock}
              />
            </Row>
          </Grid>
          <Spacer size='medium' />
        </LearnLayout>
      </Hotkeys>
    );
  }
}

Project.displayName = 'Project';

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Project));

export const query = graphql`
  query ProjectChallenge($slug: String!) {
    challengeNode(challenge: { fields: { slug: { eq: $slug } } }) {
      challenge {
        title
        description
        instructions
        challengeType
        helpCategory
        superBlock
        certification
        superOrder
        phaseOrder
        order
        challengeOrder
        block
        phase
        videoId
        translationPending
        fields {
          blockName
          slug
        }
      }
    }
  }
`;
